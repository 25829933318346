import { FormTextField } from '@dlm/common'

import useUser from '../../../common/hooks/useUser'

const TCCCommentsControl = ({ formContext, name }) => {
  const { access } = useUser()

  return (
    <FormTextField
      formContext={formContext}
      name={name}
      disabled={access.isCarrier}
    />
  )
}

export default TCCCommentsControl
