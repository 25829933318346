import axios from 'axios'

import apiConfig from '../../config/apiConfig'

export function getUserPreferences() {
  return axios.get(`${apiConfig.api.edge}/user-configs`)
}

function saveUserDashboardPreferences(dashboard) {
  const request = {
    config_details: {
      applications: {
        load_tracking: {
          favorite_dashboards: {
            load_tracking_dashboard: dashboard,
          },
        },
      },
    },
  }

  return axios.post(`${apiConfig.api.edge}/user-configs`, request)
}

function saveUserTablePreferences(table, columnUpdates) {
  const request = {
    config_details: {
      applications: {
        load_tracking: {
          tables: {
            [table]: {
              columns:
                columnUpdates?.map((update, index) => {
                  return {
                    name: update.field,
                    position: index,
                    is_visible: update.isVisible,
                    is_pinned: update.pinned,
                  }
                }) ?? [],
            },
          },
        },
      },
    },
  }

  return axios.post(`${apiConfig.api.edge}/user-configs`, request)
}

export default {
  getUserPreferences,
  saveUserTablePreferences,
  saveUserDashboardPreferences,
}
