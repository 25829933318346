import { AppContainer } from '@dlm/common'
import { concat } from 'lodash'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'
import '@dlm/common/dist/esm/css/styles.css'
import './stylesheets/customStyles.scss'

import apiConfig from './config/apiConfig'
import navigationConfig from './config/navigationConfig'

const App = () => {
  return (
    <AppContainer
      appName="Load Tracking"
      authConfig={apiConfig.auth}
      baseADGroups={concat(
        apiConfig.auth.requiredPermissions.admin,
        apiConfig.auth.requiredPermissions.user,
        apiConfig.auth.requiredPermissions.carrier,
      )}
      navigationConfig={navigationConfig}
    />
  )
}

export default App
