export const formatDefectReason = (eventConfig) => {
  if (eventConfig && eventConfig.defect_reasons) {
    return eventConfig.defect_reasons.map((status) => {
      const defectReason = `${status.code}-${status.description}`
      return { id: defectReason, label: defectReason, value: defectReason }
    })
  }
  return []
}

export const formatDefectCategories = (eventConfig) => {
  if (eventConfig && eventConfig.defect_categories) {
    return eventConfig.defect_categories.map((defectCategory) => {
      return {
        id: defectCategory,
        label: defectCategory,
        value: defectCategory,
      }
    })
  }
  return []
}

export const formatDeadheadReasonCodes = (eventConfig) => {
  if (eventConfig && eventConfig.deadhead_reason_codes) {
    return eventConfig.deadhead_reason_codes.map((reasonCode) => {
      return { id: reasonCode, label: reasonCode, value: reasonCode }
    })
  }
  return []
}

export const formatHoldReasonCodes = (eventConfig) => {
  if (eventConfig && eventConfig.hold_reason_codes) {
    return eventConfig.hold_reason_codes.map((reasonCode) => {
      return { id: reasonCode, label: reasonCode, value: reasonCode }
    })
  }
  return []
}
