import { useForm } from 'react-hook-form'
import { Button, Form, Grid } from '@enterprise-ui/canvas-ui-react'

import {
  FormAutocomplete,
  FormCheckboxes,
  FormCheckboxGroups,
  FormDateTimePicker,
  FormMultiText,
  FormSelect,
  FormTextField,
  FormRadio,
} from '@dlm/common'

const TestFormPage = () => {
  const TODAY = new Date()
  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: {
      scacField: '',
      categoryField: '',
      serviceCodeField: '',
      trailerNumberField: 0,
      originsField: ['default', 'value'],
      destinationsField: [],
      checkboxes: ['default'],
      checkboxGroup: ['default_value', 'second_default_value'],
      datePickerField: '',
      mileField: 'First',
      autocomplete: { label: 'option 2' },
      radio: 'default',
    },
  })
  const { handleSubmit, reset, formState } = formContext

  const rules = {
    scac: {
      required: {
        value: true,
        message: 'Enter valid input',
      },
      pattern: {
        value: /^.{4}$/,
        message: 'Scac code must be 4 characters long',
      },
    },
    category: {
      required: {
        value: true,
        message: 'Enter valid input',
      },
      minLength: {
        value: 1,
        message: 'Minimum 1 characters',
      },
      maxLength: {
        value: 4,
        message: 'Maximum four characters',
      },
    },
    serviceCode: {
      required: {
        value: true,
        message: 'Enter valid input',
      },
    },
    trailerNumber: {
      required: {
        value: true,
        message: 'Enter valid input',
      },
      min: {
        value: 0,
        message: 'Trailer number must not be less than 0',
      },
    },
    checkboxes: {
      required: {
        value: true,
      },
    },
    radio: {
      required: {
        value: true,
      },
    },
    originsField: {
      required: {
        value: true,
        message: 'Origin IDs are required',
      },
      validate: {
        alphanumeric: (v) =>
          v.every((e) => /^[0-9a-zA-Z]+$/.test(e)) || 'must be alphanumeric',
        lessThanThree: (v) =>
          v.length <= 3 || 'only up to three options allowed',
      },
    },
    destinationsField: {
      required: {
        value: false,
      },
    },
    checkboxGroup: {
      required: {
        value: false,
      },
    },
    startDate: {
      validate: {
        disablePast: (value) => {
          if (new Date(value) < TODAY) {
            return 'Enter a date after today'
          }
        },
      },
    },
    auction: {
      required: {
        value: true,
        message: 'Make a selection',
      },
    },
  }

  return (
    <Form id="test-form" onSubmit={handleSubmit((data) => console.log(data))}>
      <Grid.Container>
        <Grid.Item xs>
          <FormTextField
            name="scacField"
            className="form-field"
            placeholder="Enter scac value"
            formContext={formContext}
            rules={rules.scac}
            onChange={(value) => console.log(value)}
            onBlur={() => console.log('onBlur scac')}
            label="Scac"
          />
        </Grid.Item>
        <Grid.Item xs>
          <FormTextField
            name="categoryField"
            className="form-field"
            placeholder="Enter category value"
            formContext={formContext}
            rules={rules.category}
            onChange={(value) => console.log(value)}
            onBlur={() => console.log('onBlur category')}
            label="Category"
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs>
          <FormTextField
            name="serviceCodeField"
            className="form-field"
            placeholder="Enter service code value"
            formContext={formContext}
            rules={rules.serviceCode}
            onChange={(value) => console.log(value)}
            onBlur={() => console.log('onBlur service code')}
            label="Service code"
          />
        </Grid.Item>
        <Grid.Item xs>
          <FormTextField
            name="trailerNumberField"
            className="form-field"
            placeholder="Enter trailer number value"
            formContext={formContext}
            rules={rules.trailerNumber}
            onChange={(value) => console.log(value)}
            onBlur={() => console.log('onBlur trailer')}
            label="Trailer Number"
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs>
          <FormSelect
            name="mileField"
            className="form-field"
            placeholder="Select Mile"
            formContext={formContext}
            onChange={(id, value) => {
              console.log(`id is ${id}`)
              console.log(`value is ${value}`)
            }}
            onBlur={() => console.log('onBlur mile')}
            label="Mile"
            errorText="Error"
            options={[
              { label: 'First Mile', value: 'First' },
              { label: 'Middle Mile', value: 'Middle' },
            ]}
          />
        </Grid.Item>
        <Grid.Item xs>
          <FormSelect
            name="moveField"
            className="form-field"
            placeholder="Select Move Type"
            formContext={formContext}
            onChange={(id, value) => {
              console.log(`id is ${id}`)
              console.log(`value is ${value}`)
            }}
            onBlur={() => console.log('onBlur move')}
            label="Move"
            errorText="Error"
            options={[
              { label: '', value: undefined },
              { label: 'General Merchandise', value: 'GM' },
              { label: 'Food', value: 'FOOD' },
            ]}
          />
        </Grid.Item>
        <Grid.Item xs>
          <FormSelect
            name="loadAuctionField"
            className="form-field"
            placeholder="Select Auction Status"
            formContext={formContext}
            rules={rules.auction}
            onChange={(id, value) => {
              console.log(`id is ${id}`)
              console.log(`value is ${value}`)
            }}
            onBlur={() => console.log('onBlur auction history')}
            label="Auction Status"
            errorText="Error"
            options={[
              { label: '', value: undefined },
              { label: 'In Auction', value: 'In Auction' },
              { label: 'Auction History', value: 'Auction History' },
            ]}
          />
        </Grid.Item>
        <Grid.Item xs>
          <FormDateTimePicker
            name="datePickerField"
            type="date"
            className="form-field"
            formContext={formContext}
            rules={rules.startDate}
            onChange={(value) => console.log(value)}
            onBlur={() => console.log('onBlur date')}
            label="Load Start Date"
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs>
          <FormMultiText
            name="originsField"
            formContext={formContext}
            rules={rules.originsField}
            placeholder="Origin IDs"
            label="Origin IDs"
            className="form-field"
          />
        </Grid.Item>
        <Grid.Item xs>
          <FormMultiText
            name="destinationsField"
            formContext={formContext}
            rules={rules.destinationsField}
            placeholder="Destination IDs"
            label="Destination IDs"
            className="form-field"
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs>
          <FormCheckboxes
            name="checkboxes"
            formContext={formContext}
            rules={rules.checkboxes}
            label="Checkboxes"
            className="form-field"
            options={[
              { label: 'Default Value', value: 'default' },
              { label: 'Other', value: 'other' },
              { label: 'Bonus', value: 'bonus' },
            ]}
            onChange={(value) => console.log(`checkboxes value ${value}`)}
          />
        </Grid.Item>
        <Grid.Item xs>
          <FormRadio
            name="radio"
            formContext={formContext}
            rules={rules.radio}
            label="Radio"
            className="form-field"
            options={[
              { label: 'Default Value', value: 'default' },
              { label: 'Another', value: 'another' },
              { label: 'Different', value: 'different' },
            ]}
            onChange={(value) => console.log(`Radio value ${value}`)}
          />
        </Grid.Item>
        <Grid.Item xs>
          <FormCheckboxGroups
            name="checkboxGroup"
            formContext={formContext}
            rules={rules.checkboxGroup}
            label="Checkbox Group"
            className="form-field"
            options={[
              {
                label: 'Default Values',
                values: [
                  { label: 'Default Value', value: 'default_value' },
                  {
                    label: 'Second Default Value',
                    value: 'second_default_value',
                  },
                ],
              },
              {
                label: 'Other Values',
                values: [
                  { label: 'Other Value', value: 'other_value' },
                  { label: 'Other Other Value', value: 'other_other_value' },
                ],
              },
            ]}
            onChange={(values) =>
              console.log(`checkbox group onChange ${values}`)
            }
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs>
          <FormDateTimePicker
            name="dateTimePickerField"
            type="datetime-local"
            className="form-field"
            formContext={formContext}
            onChange={(value) => console.log(value)}
            onBlur={() => console.log('onBlur date')}
            label="Critical Departure Time"
          />
        </Grid.Item>
        <Grid.Item xs>
          <FormAutocomplete
            name="autocomplete"
            className="form-field"
            formContext={formContext}
            onChange={(id, value) => console.log(id, value)}
            label="Autocomplete"
            options={[
              {
                id: 0,
                label: `option 1`,
                value: `option 1`,
              },
              {
                id: 1,
                label: 'option 2a',
                value: 'option 2a',
              },
              {
                id: 2,
                label: 'option 2b',
                value: 'option 2b',
              },
            ]}
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container justify={'center'}>
        <Button
          disabled={!formState.isValid}
          className="form-button"
          type="submit"
        >
          Submit
        </Button>
        <Button
          className="form-button"
          type="secondary"
          onClick={() => reset()}
        >
          Reset
        </Button>
      </Grid.Container>
    </Form>
  )
}

export default TestFormPage
