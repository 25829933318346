import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  Grid,
  Heading,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { HouseIcon } from '@enterprise-ui/icons'

import { ProtectedElement } from '@dlm/common'

import apiConfig from '../../config/apiConfig'

const AdminTestPage = () => {
  return (
    <ProtectedElement allowed={apiConfig.auth.requiredPermissions.admin}>
      <Card className="topPage-container">
        <Grid.Container
          justify="center"
          align="center"
          direction="column"
          className="fullHeight-container"
        >
          <Grid.Item>
            <Heading size={2}>Admin test Page</Heading>
          </Grid.Item>
          <Grid.Item>
            <Heading size={5}>
              This is just a test page to test admin authorization
            </Heading>
          </Grid.Item>
          <Grid.Item>
            <Tooltip content="Return Home" location="top">
              <Button
                iconOnly
                size="expanded"
                type="primary"
                as={Link}
                to="/"
                aria-label="Go to the homepage"
              >
                <EnterpriseIcon size="xl" icon={HouseIcon} />
              </Button>
            </Tooltip>
          </Grid.Item>
        </Grid.Container>
      </Card>
    </ProtectedElement>
  )
}

export default AdminTestPage
