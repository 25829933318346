import LoadTracking from '../views/LoadTracking/LoadTracking'
import AdminTestPage from '../_sandbox/views/AdminTestPage'
import TestFormPage from '../_sandbox/views/TestFormPage'
import Dashboard from '../views/Dashboard/Dashboard'

const routes = [
  {
    path: '/',
    component: <LoadTracking />,
  },
  {
    path: '/dashboard',
    component: <Dashboard />,
    // TODO: remove for prod release
    hideInEnvironment: ['prod'],
  },
  {
    path: '/sandbox/admintest',
    component: <AdminTestPage />,
    hideInEnvironment: ['prod', 'stg'],
  },
  {
    path: '/sandbox/testform',
    label: 'Test Form Page',
    component: <TestFormPage />,
    hideInEnvironment: ['prod', 'stg'],
  },
]

const sideNavItems = {
  loadtracking: {
    type: 'item',
    path: '/',
    label: 'Load Tracking',
  },
  dashboard: {
    type: 'item',
    path: '/dashboard',
    label: 'Dashboard',
  },
  sandbox: {
    type: 'group',
    label: 'Sandbox',
    hideInEnvironment: ['prod', 'stg'],
    items: [
      {
        path: '/sandbox/admintest',
        label: 'Admin Test Page',
      },
      {
        path: '/sandbox/testform',
        label: 'Test Form Page',
      },
    ],
  },
}

export default {
  routes,
  sideNavItems,
}
