export const findMultiPickRuleValues = (rules) => {
  const multiPickRule = rules
    ? rules.find(
        (rule) =>
          rule.rule === 'subcategory' && rule.rule_type === 'MULTI_PICK_LOAD',
      )
    : null
  return multiPickRule &&
    multiPickRule.rule_values &&
    multiPickRule.rule_values.value_list
    ? multiPickRule.rule_values.value_list
    : []
}
