import { includes, map } from 'lodash'

export const autoCompleteValidationRule = (eventConfigValueList) => {
  return {
    validate: {
      validateInputValue: (value) => {
        const optionValues = map(
          [{ label: '', value: '' }, ...eventConfigValueList],
          (option) => option.value,
        )
        return includes(optionValues, value?.value) || 'Invalid input'
      },
    },
  }
}

export const originDestinationFilterValidationRule = () => {
  return {
    validate: {
      alphanumeric: (v) => {
        if (v === null || v[0]?.value === undefined) return true
        return /^[0-9a-zA-Z]+$/.test(v[0]?.value) || 'Must be alphanumeric'
      },
    },
  }
}
