import { isArray } from 'lodash'
import { NovaFilterDrawer } from '@dlm/common'

const DashboardSearchFilterDrawer = ({
  onChange,
  isVisible,
  onRequestClose,
  onSaveSearch,
  givenValues,
  savedSearches,
  defaultValues,
}) => {
  const mapOriginDestinationValues = (values) => {
    if (values === undefined || values === null) return
    values['origin_ids'] = values['origin_ids']?.map(
      (origin) => origin?.value || origin,
    )
    values['destination_ids'] = values['destination_ids']?.map(
      (destination) => destination?.value || destination,
    )
    return values
  }

  const handleChange = (values) => {
    // Needed to strip out object metadata added by hook form for tests to pass
    onChange(Object.assign({}, values))
    onRequestClose()
  }

  function handleSaveSearch(values) {
    onSaveSearch(values)
  }

  const filterMeta = {
    1: [
      {
        type: 'multi-text',
        name: 'scacs',
        label: 'SCACs',
        ariaLabel: 'SCAC List',
        placeholder: 'Comma separated list of SCACs',
        xs: 12,
      },
    ],
    2: [
      {
        type: 'multi-text',
        name: 'origin_ids',
        label: 'Origin IDs',
        ariaLabel: 'Origin ID List',
        placeholder: 'Comma separated list of Origin IDs',
        xs: 12,
      },
    ],
    3: [
      {
        type: 'datetime-local',
        name: 'critical_departure_start_time',
        label: 'Critical Departure Start Time',
        ariaLabel: 'Critical Departure Start Date Time',
        xs: 6,
      },
      {
        type: 'datetime-local',
        name: 'critical_departure_end_time',
        label: 'Critical Departure End Time',
        ariaLabel: 'Critical Departure End Date Time',
        xs: 6,
      },
    ],
    4: [
      {
        type: 'datetime-local',
        name: 'arrival_start_time',
        label: 'Arrival Start Time',
        ariaLabel: 'Arrival Start From Date Time',
        xs: 6,
      },
      {
        type: 'datetime-local',
        name: 'arrival_end_time',
        label: 'Arrival End Time',
        ariaLabel: 'Arrival Start End Date Time',
        xs: 6,
      },
    ],
    5: [
      {
        type: 'checkboxes',
        name: 'load_statuses',
        label: 'Load Status',
        options: [
          { label: 'Tendered', value: 'TENDERED' },
          { label: 'Tender Accepted', value: 'TENDER_ACCEPTED' },
          { label: 'Complete', value: 'COMPLETE' },
          { label: 'In Transit', value: 'IN_TRANSIT' },
        ],
        xs: 12,
      },
    ],
  }

  const existingSearches = isArray(savedSearches) ? savedSearches : []

  const filterActionsMeta = {
    1: [
      {
        type: 'reset',
        xs: 6,
      },
      {
        type: 'save-search',
        xs: 6,
        action: handleSaveSearch,
        currentSavedSearches: existingSearches,
      },
    ],
    2: [
      {
        type: 'submit',
        xs: 12,
      },
    ],
  }

  return (
    <NovaFilterDrawer
      isVisible={isVisible}
      onRequestClose={onRequestClose}
      headingText="Dashboard Search Filters"
      location="right"
      filterMeta={filterMeta}
      filterActionsMeta={filterActionsMeta}
      defaultValues={defaultValues}
      values={mapOriginDestinationValues(givenValues)}
      onSubmit={handleChange}
    />
  )
}

export default DashboardSearchFilterDrawer
