import { isArray, isNil } from 'lodash'

const createParams = (params) => {
  const urlParams = new URLSearchParams()

  Object.entries(params).forEach(([key, value]) => {
    if (isNil(value) || value === '') return

    if (isArray(value)) {
      value.forEach((arrayEntry) => urlParams.append(key, arrayEntry))
    } else {
      urlParams.append(key, value)
    }
  })

  return urlParams
}

export default {
  createParams,
}
