import axios from 'axios'
import apiConfig from '../../../config/apiConfig'
import qsUtil from '../../../common/util/queryStringUtil'
import { FIRST_MILE, MIDDLE_MILE } from '../constants/dashboardConstants'

async function getFMLoadSummary(summarySearchFilters) {
  const params = formatToSummaryParams(summarySearchFilters, FIRST_MILE)
  const { data } = await axios.get(`${apiConfig.api.edge}/dashboard`, {
    params: qsUtil.createParams(params),
  })
  return data
}

async function getMMLoadSummary(summarySearchFilters) {
  const params = formatToSummaryParams(summarySearchFilters, MIDDLE_MILE)
  const { data } = await axios.get(`${apiConfig.api.edge}/dashboard`, {
    params: qsUtil.createParams(params),
  })
  return data
}

export function formatToSummaryParams(summarySearchFilters, dashboardView) {
  switch (dashboardView) {
    case FIRST_MILE:
      return {
        ...summarySearchFilters,
        dashboard_type: 'FM_OUTSTANDING_LOADS',
      }
    case MIDDLE_MILE:
      return {
        ...summarySearchFilters,
        dashboard_type: 'MM_GM_DASHBOARD',
      }
    default:
      return {}
  }
}

export default {
  getFMLoadSummary,
  getMMLoadSummary,
}
