export const ALERT_STATUS = {
  AT_RISK_CRITICAL_DEPARTURE_TIME: 'At Risk, Critical Departure Time',
  AT_RISK_LATE_DEPARTURE: 'At Risk, Late Departure',
  AT_RISK_PAST_EXPECTED_DELIVERY: 'At Risk, Past Expected Delivery',
  AT_RISK_PAST_EXPECTED_PICKUP: 'At Risk, Past Expected Pickup',
  AT_RISK_PICKUP_ARRIVAL_TIME: 'At Risk, Pickup Arrival Time',
  EARLY_DELIVERY: 'Early Delivery',
  EARLY_PICKUP: 'Early Pickup',
  LATE_DELIVERY: 'Late Delivery',
  LATE_PICKUP: 'Late Pickup',
}
