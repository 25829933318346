import { Button, Chip, Grid } from '@enterprise-ui/canvas-ui-react'
import { FILTER_LABELS } from '../constants/searchFilterConstants'

const SearchFilterChips = ({ searchFilters, onDelete, onReset }) => {
  return (
    <Grid.Container align="center">
      <Grid.Item>
        <Button
          data-testid="sf_reset_button"
          onClick={onReset}
          type="primary"
          disabled={searchFilters.selected_filters.length === 0}
        >
          Reset Filters
        </Button>
      </Grid.Item>
      <Grid.Item>
        {searchFilters.selected_filters.map((value, index) => (
          <Chip
            key={`filter-chip-${value}-${index}`}
            className="hc-ma-none"
            data-testid={`filter_chip_${value}`}
            onRequestDelete={() => {
              onDelete(value)
            }}
          >
            {FILTER_LABELS[value]}
          </Chip>
        ))}
      </Grid.Item>
    </Grid.Container>
  )
}

export default SearchFilterChips
