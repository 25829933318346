import { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useAuth } from '@praxis/component-auth'
import {
  chain,
  clone,
  find,
  isEmpty,
  isEqual,
  isString,
  omit,
  pull,
} from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Button,
  ButtonGroup,
  Grid,
  Layout,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { FilterIcon } from '@enterprise-ui/icons'
import '@enterprise-ui/canvas-ui-css-autocomplete'

import { ProgressOverlay, SavedSearchMenu } from '@dlm/common'

import LoadTrackingSearchFilterDrawer from './components/LoadTrackingSearchFilterDrawer'
import LoadLevelTable from './components/LoadLevelTable'
import StopLevelTable from './components/StopLevelTable'
import SearchFilterChips from '../../common/components/SearchFilterChips'

import trackingService from './services/trackingService'
import savedSearchService from '../../common/services/savedSearchService'
import userPreferenceService from '../../common/services/userPreferenceService'

import { DEFAULT_LOAD_SEARCH_FILTERS } from './constants/loadSearchFilterConstants'
import apiConfig from '../../config/apiConfig'

import '../../stylesheets/customStyles.scss'
import '@dlm/common/dist/esm/css/styles.css'

const DEFAULT_SORT_COLUMN = 'load_id'
const INITIAL_SEARCH_FILTERS = {
  page: 1,
  per_page: 100,
  sort_by: DEFAULT_SORT_COLUMN,
  is_descending_sort: true,
  selected_filters: [],
  ...DEFAULT_LOAD_SEARCH_FILTERS,
}

const LoadTracking = () => {
  const auth = useAuth()
  const { session } = auth
  const user = session?.userInfo
  const accessToken = session?.accessToken

  // Configure any axios interceptors here
  // Usually we set interceptors globally, but this needs to be inside the component to work with federation
  axios.interceptors.request.use((config) => {
    config.headers['X-API-KEY'] = apiConfig.api.key
    // Usually populated by praxis by default, but doesn't work if accessed from parent mfe app
    config.headers['Authorization'] =
      accessToken && accessToken.includes('Bearer')
        ? accessToken
        : `Bearer ${accessToken}`
    return config
  })

  const makeToast = ToastProvider.useToaster()

  const [userPreferences, setUserPreferences] = useState({})

  const [filtersVisible, setFiltersVisible] = useState(false)

  const [savedSearchData, setSavedSearchData] = useState('')

  const [loadSearchFilters, setLoadSearchFilters] = useState(
    INITIAL_SEARCH_FILTERS,
  )

  const [loadData, setLoadData] = useState([])
  const [selectedView, setSelectedView] = useState('loadView')
  const [loadStatusOptions, setLoadStatusOptions] = useState([])
  const [alertStatusOptions, setAlertStatusOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [holdReasonCodeOptions, setHoldReasonCodeOptions] = useState([])
  const [requiredActionsOptions, setRequiredActionsOptions] = useState([])
  const [nodeInfo, setNodeInfo] = useState([])
  const [eventConfig, setEventConfig] = useState([])
  const [loadCount, setLoadCount] = useState(0)
  const [inProgress, setInProgress] = useState(false)
  const [rules, setRules] = useState([])

  const changeView = (e) => {
    setSelectedView(e.target.id)
  }

  const onPaginationChange = (pageNum, pageSize) => {
    setLoadSearchFilters((loadSearchFilters) => ({
      ...loadSearchFilters,
      page: pageNum,
      per_page: pageSize,
    }))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchFilterChange = (newFilters) => {
    const updatedSelectedFilters = chain(newFilters)
      .pick(Object.keys(DEFAULT_LOAD_SEARCH_FILTERS))
      .omitBy(isEmpty)
      .keys()
      .value()

    // Need to re-instate pagination and sort properties as filter drawer strips them
    setLoadSearchFilters({
      ...newFilters,
      page: 1,
      per_page: loadSearchFilters.per_page,
      sort_by: loadSearchFilters.sort_by,
      is_descending_sort: loadSearchFilters.is_descending_sort,
      selected_filters: updatedSelectedFilters,
    })
  }

  const onSaveSearch = (newFilters) => {
    savedSearchService
      .saveSearch(newFilters, 'LOAD_TRACKING_SAVED_SEARCH')
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully saved search',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error saving search',
        })
      })
  }

  const deleteSavedSearch = (criteriaId) => {
    savedSearchService
      .deleteSavedSearch(criteriaId)
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully deleted search',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error deleting search',
        })
      })
  }

  const markDefault = (criteriaId) => {
    savedSearchService
      .setDefaultSavedSearch(criteriaId)
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully marked default',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error marking default',
        })
      })
  }

  const onSearchFilterDelete = (filter) => {
    setLoadSearchFilters({
      ...loadSearchFilters,
      selected_filters: pull(loadSearchFilters.selected_filters, filter),
      [filter]: DEFAULT_LOAD_SEARCH_FILTERS[filter],
    })
  }

  const onSearchFilterReset = () => {
    setLoadSearchFilters({
      page: 1,
      per_page: loadSearchFilters.per_page,
      sort_by: loadSearchFilters.sort_by,
      is_descending_sort: loadSearchFilters.is_descending_sort,
      selected_filters: [],
      ...DEFAULT_LOAD_SEARCH_FILTERS,
    })
  }

  const onSortChange = (direction, column) => {
    // direction `null` is a sort reset for that column.
    // Since we only allow sorting by a single column, null means revert to default sort
    const sortBy = direction === null ? DEFAULT_SORT_COLUMN : column
    const isDescendingSort = direction === null ? true : direction === 'dsc'

    setLoadSearchFilters((loadSearchFilters) => ({
      ...loadSearchFilters,
      page: 1,
      per_page: loadSearchFilters.per_page,
      sort_by: sortBy,
      is_descending_sort: isDescendingSort,
    }))
  }

  const onColumnDefsChange = (table, columnUpdates) => {
    userPreferenceService
      .saveUserTablePreferences(table, columnUpdates)
      .then(({ data }) => {
        setUserPreferences(data)
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error saving changes',
        })
      })
  }

  const onUpdate = (recordUpdates) => {
    trackingService
      .saveUpdates(recordUpdates, user)
      .then(() => {
        // Refresh the loads. Usually we'll call the api directly,
        // but we have a useEffect that would do it much more easily with filter update
        setLoadSearchFilters(clone(loadSearchFilters))
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Changes successfully saved',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error saving changes',
        })
      })
  }

  const getLoads = useCallback(() => {
    if (preloadedFiltersRef.current) {
      setInProgress(true)
      trackingService
        .getLoads(omit(loadSearchFilters, 'selected_filters'))
        .then((resp) => {
          setLoadCount(resp.row_count)
          setLoadData(resp.loads)
        })
        .catch(() => {
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Error fetching load details',
          })
        })
        .finally(() => {
          // TODO - Remove timeout after https://jira.target.com/browse/EUI-1740 is implemented
          setTimeout(() => setInProgress(false), 500)
        })
    }
  }, [loadSearchFilters, makeToast])

  const getSavedSearches = () => {
    savedSearchService
      .getSavedSearches('LOAD_TRACKING_SAVED_SEARCH')
      .then((mappedResponse) => {
        setSavedSearchData(mappedResponse)
      })
  }

  const exportCSV = (type) => {
    if (loadCount <= 500) {
      trackingService
        .getCSVReport(loadSearchFilters, user, type)
        .then(() => {
          makeToast({
            type: 'success',
            heading: 'Success',
            message: `Successfully sent CSV report to email: ${user.email}`,
          })
        })
        .catch((error) => {
          console.error(error)
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Error creating CSV report',
          })
        })
    } else {
      makeToast({
        type: 'error',
        heading: `Load count from query is too large: ${loadCount}, please narrow your search filters`,
        message: 'Error creating CSV report',
      })
    }
  }

  useEffect(() => {
    if (!isEqual(loadSearchFilters, INITIAL_SEARCH_FILTERS)) {
      getLoads()
    }
  }, [getLoads, loadSearchFilters])

  // On Load setup
  useEffect(() => {
    // Fetch saved searches
    getSavedSearches()

    // Fetch metadata
    trackingService
      .getCriteria(7)
      .then((resp) => {
        const loadPredicate = resp.predicate_json.load_predicate
        setLoadStatusOptions(
          loadPredicate.status_list.map((status) => {
            return { label: status.replaceAll('_', ' '), value: status }
          }),
        )
        setAlertStatusOptions(
          loadPredicate.alert_status_list.map((alertStatus) => {
            return {
              label: alertStatus.replaceAll('_', ' '),
              value: alertStatus,
            }
          }),
        )
        setRequiredActionsOptions(
          loadPredicate.required_actions_list?.map((action) => {
            return {
              label: action,
              value: action,
            }
          }),
        )
        setCategoryOptions(loadPredicate.category_list)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching criteria details',
        })
      })

    trackingService
      .getEventTypes(user)
      .then((resp) => {
        setHoldReasonCodeOptions(
          resp.hold_reason_codes.map((holdReasonCode) => {
            return {
              label: holdReasonCode.replaceAll('_', ' '),
              value: holdReasonCode,
            }
          }),
        )
        setEventConfig(resp)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching event types',
        })
      })

    trackingService
      .getRules()
      .then((resp) => {
        setRules(resp)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching rules',
        })
      })

    trackingService
      .getLocation(user)
      .then((resp) => {
        const nodeInfoData = resp.map((node) => ({
          label: `${node.node_id} - ${node.display_name}`,
          value: node.node_id,
          id: node.node_id,
          group: node.sub_type_code,
        }))
        setNodeInfo(nodeInfoData)
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error fetching location data',
        })
      })

    userPreferenceService
      .getUserPreferences()
      .then(({ data }) => {
        setUserPreferences(data)
      })
      .catch((error) => {
        if (error?.response?.status !== 404) {
          console.error(error)
          makeToast({
            type: 'error',
            heading: 'Server Error',
            message: 'Error loading user preferences',
          })
        }
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mapStateFilters = (filters) => {
    const mappedFilters = ['origin_ids', 'destination_ids']
    mappedFilters.forEach((filter) => {
      filters[filter] = filters[filter]?.flatMap((filterValue) => {
        return {
          value: filterValue,
          label: filterValue,
          id: filterValue,
        }
      })
    })
    return filters
  }

  // Perform default saved Search (If any) on load

  const location = useLocation()
  const navigate = useNavigate()
  const preloadedFiltersRef = useRef(null)
  useEffect(() => {
    if (
      !preloadedFiltersRef.current &&
      !isEmpty(savedSearchData) &&
      location.state == null
    ) {
      // Saved searches loaded. We can now fetch default saved search or default search criteria
      const defaultSavedSearch =
        !isString(savedSearchData) &&
        find(savedSearchData, { default_search: true })
      if (defaultSavedSearch) {
        onSearchFilterChange(defaultSavedSearch.filters)
      } else {
        setLoadSearchFilters(clone(loadSearchFilters))
      }
    }

    if (!isEmpty(savedSearchData)) {
      if (location.state !== null) {
        if (!isEmpty(categoryOptions)) {
          preloadedFiltersRef.current = location.state
          navigate(location.state, { replace: true })
          onSearchFilterChange(mapStateFilters(location.state.filters))
        }
      } else {
        preloadedFiltersRef.current = savedSearchData
      }
    }
  }, [
    categoryOptions,
    loadSearchFilters,
    location,
    makeToast,
    navigate,
    savedSearchData,
    onSearchFilterChange,
  ])

  // TODO: make table -height- responsive
  return (
    <>
      <ProgressOverlay inProgress={inProgress} />
      <LoadTrackingSearchFilterDrawer
        isVisible={filtersVisible}
        onRequestClose={() => {
          setFiltersVisible(false)
        }}
        onChange={onSearchFilterChange}
        loadStatusOptions={loadStatusOptions}
        alertStatusOptions={alertStatusOptions}
        loadCategories={categoryOptions}
        holdReasonCodeOptions={holdReasonCodeOptions}
        requiredActionsOptions={requiredActionsOptions}
        nodeInfo={nodeInfo}
        onSaveSearch={onSaveSearch}
        givenValues={loadSearchFilters}
        savedSearches={savedSearchData}
      />
      <Layout.Body data-testid="loadTracking" includeRail>
        <Grid.Container justify="space-between" style={{ marginBottom: 10 }}>
          <Grid.Item>
            <ButtonGroup>
              <Button
                id="loadView"
                onClick={changeView}
                aria-label="Load View Button"
                data-testid="Load-View-Button"
                type={selectedView === 'loadView' ? 'primary' : 'secondary'}
              >
                Load View
              </Button>
              <Button
                id="stopView"
                onClick={changeView}
                aria-label="Stop View Button"
                type={selectedView === 'stopView' ? 'primary' : 'secondary'}
              >
                Stop View
              </Button>
            </ButtonGroup>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container>
              <SavedSearchMenu
                mappedSavedSearchData={savedSearchData}
                onLoadSearch={onSearchFilterChange}
                onToggleDefaultSearch={markDefault}
                onDelete={deleteSavedSearch}
              />
              <Grid.Item>
                <Button
                  onClick={() => {
                    setFiltersVisible(true)
                  }}
                  aria-label="Show Search Filters"
                  data-testid="filters-button"
                >
                  <EnterpriseIcon
                    icon={FilterIcon}
                    size="sm"
                    style={{ marginRight: 5 }}
                  />
                  Show Filters
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container>
          <Grid.Item xs={12}>
            <>
              {selectedView === 'loadView' && (
                <LoadLevelTable
                  aria-label="Load View Table"
                  data-testid="Load-View-Table"
                  loads={loadData}
                  loadSearchFilters={loadSearchFilters}
                  loadCount={loadCount}
                  eventConfig={eventConfig}
                  pageNum={loadSearchFilters.page}
                  pageSize={loadSearchFilters.per_page}
                  userPreferences={userPreferences}
                  onExport={exportCSV}
                  onRefresh={getLoads}
                  rules={rules}
                  subHeader={
                    <SearchFilterChips
                      searchFilters={loadSearchFilters}
                      onDelete={onSearchFilterDelete}
                      onReset={onSearchFilterReset}
                    />
                  }
                  onUpdate={onUpdate}
                  onPaginationChange={onPaginationChange}
                  onSortChange={onSortChange}
                  onColumnDefsChange={onColumnDefsChange}
                />
              )}
              {selectedView === 'stopView' && (
                <StopLevelTable
                  aria-label="Stop View Table"
                  loads={loadData}
                  loadCount={loadCount}
                  eventConfig={eventConfig}
                  pageNum={loadSearchFilters.page}
                  pageSize={loadSearchFilters.per_page}
                  userPreferences={userPreferences}
                  onExport={exportCSV}
                  onRefresh={getLoads}
                  rules={rules}
                  subHeader={
                    <SearchFilterChips
                      searchFilters={loadSearchFilters}
                      onDelete={onSearchFilterDelete}
                      onReset={onSearchFilterReset}
                    />
                  }
                  onUpdate={onUpdate}
                  onPaginationChange={onPaginationChange}
                  onSortChange={onSortChange}
                  onColumnDefsChange={onColumnDefsChange}
                />
              )}
            </>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}

export default LoadTracking
