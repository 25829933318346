import { ALERT_STATUS } from '../constants/alertConstants'

export const getAlertStatusColor = (alertStatus) => {
  let status
  switch (alertStatus) {
    case ALERT_STATUS.AT_RISK_CRITICAL_DEPARTURE_TIME:
    case ALERT_STATUS.AT_RISK_PAST_EXPECTED_DELIVERY:
    case ALERT_STATUS.AT_RISK_PAST_EXPECTED_PICKUP:
    case ALERT_STATUS.AT_RISK_PICKUP_ARRIVAL_TIME:
      status = 'alert'
      break
    case ALERT_STATUS.AT_RISK_LATE_DEPARTURE:
    case ALERT_STATUS.EARLY_DELIVERY:
    case ALERT_STATUS.EARLY_PICKUP:
    case ALERT_STATUS.LATE_DELIVERY:
    case ALERT_STATUS.LATE_PICKUP:
      status = 'error'
      break
    default:
      status = 'default'
  }
  return status
}
