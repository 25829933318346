export const LOAD_STATUS = {
  AUCTION: 'AUCTION',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
  IN_TRANSIT: 'IN_TRANSIT',
  TENDER_ACCEPTED: 'TENDER_ACCEPTED',
  TENDER_CANCELED: 'TENDER_CANCELED',
  TENDER_EXPIRED: 'TENDER_EXPIRED',
  TENDER_REJECTED: 'TENDER_REJECTED',
  TENDERED: 'TENDERED',
}
