import {
  ActionArea,
  Button,
  Card,
  Grid,
  Heading,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { NavLink } from 'react-router-dom'
import { sortBy } from 'lodash'
import EnterpriseIcon, { HelpIcon } from '@enterprise-ui/icons'
import { useState } from 'react'

const DescriptionModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        <EnterpriseIcon icon={HelpIcon} size="sm" style={{ marginRight: 5 }} />
        About
      </Button>
      <Modal
        headingText="About the Domestic First Mile Outstanding Load Summary"
        isVisible={isVisible}
        onRefuse={() => setIsVisible(false)}
        size="normal"
      >
        <div className="hc-pa-normal">
          <Grid.Container direction="column">
            <Grid.Item>
              <div>
                The purpose of this dashboard is to give visibility to at risk
                and late loads and ensure critical updates are provided by
                carriers to Target. Loads will fall in to the following
                categories if they meet the below criteria. To view the specific
                load details and provide updates to those loads, click on any of
                the numeric values and the loads will pre-populate in DLM load
                Tracking.
              </div>
            </Grid.Item>
            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  At Risk, Past Expected Pickup
                </div>
              </strong>
              <ul>
                <li>Expected pickup date is in the past</li>
                <li>EDI 214 AF status has not been received</li>
              </ul>
            </Grid.Item>
            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  At Risk, Past Expected Pickup status requiring action
                </div>
              </strong>
              <ul>
                <li>Expected pickup date is in the past</li>
                <li>EDI 214 AF status has not been received</li>
                <li>
                  Estimated pickup date has not been entered or is in the past
                </li>
                <li>Pickup defect reason code has not been entered</li>
              </ul>
            </Grid.Item>
            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  Late Pickup status requiring action
                </div>
              </strong>
              <ul>
                <li>EDI 214 AF timestamp was after the expected pickup date</li>
                <li>Pickup defect reason code has not been entered</li>
              </ul>
            </Grid.Item>
            <Grid.Item>
              <strong>
                <div className="hc-fs-md hc-pb-dense">
                  Total loads requiring action
                </div>
              </strong>
              <ul>
                <li>
                  Any loads that fall in to: At Risk, Past Expected Pickup
                  status requiring action OR Late Pickup status requiring action
                </li>
              </ul>
            </Grid.Item>
            <Grid.Item>
              <Grid.Container justify="flex-end">
                <Grid.Item>
                  <Button onClick={() => setIsVisible(false)}>Close</Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}

const FMOutstandingLoadSummary = ({
  loadSummary,
  subHeader,
  formatStateFilters = (_filters) => {},
}) => {
  const mapLoadSummary = (loadSummary) => {
    const dashboardSummaries = loadSummary?.dashboard_summaries
    const scacs = sortBy(
      [
        ...new Set(dashboardSummaries?.map((details) => details.scac)?.sort()), // Set ensures these scac values are unique
      ],
      (scac) => (scac === 'All' ? 0 : 1),
    )
    return scacs.map((scac) => {
      const allSummariesForScac = dashboardSummaries
        .filter((summary) => summary.scac === scac)
        .flatMap((summary) => {
          return summary.summary_cells
        })
      const cellDisplaySequences = [
        ...new Set(
          allSummariesForScac.flatMap((summary) => {
            return summary.display_sequence
          }),
        ),
      ].sort()
      return {
        scac: scac,
        summaries: cellDisplaySequences.map((displaySequence) => {
          const summariesForCell = allSummariesForScac.filter(
            (summary) => summary.display_sequence === displaySequence,
          )
          const firstSummaryForCell = allSummariesForScac.find(
            (summary) => summary.display_sequence === displaySequence,
          )
          const allFilters = summariesForCell.flatMap(
            (summary) => summary.filters,
          )
          const filterNames = [
            ...new Set(allFilters.map((filter) => filter.filter_name)),
          ]
          const mappedFilters = filterNames.map((filterName) => {
            return {
              filter_name: filterName,
              filter_values: [
                ...new Set(
                  allFilters
                    .filter((filter) => filter.filter_name === filterName)
                    .flatMap((filter) => filter.filter_values),
                ),
              ],
            }
          })
          return {
            display_sequence: displaySequence,
            display_name: firstSummaryForCell?.display_name,
            count: summariesForCell.reduce((acc, summary) => {
              acc += summary.load_count
              return acc
            }, 0),
            filters: formatStateFilters(mappedFilters),
          }
        }),
      }
    })
  }

  return (
    <Card className="hc-pa-expanded">
      <Grid.Container direction="column">
        <Grid.Item>
          <Grid.Container justify="space-between">
            <Grid.Item>
              <Heading size={3}>
                Domestic First Mile Outstanding Loads Summary
              </Heading>
            </Grid.Item>
            <Grid.Item>
              <DescriptionModal />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item>{subHeader}</Grid.Item>
        {mapLoadSummary(loadSummary)?.map((summary, index) => {
          return (
            <Grid.Item xs={12} data-testid={`outstandingloadsummary-${index}`}>
              <Grid.Container>
                <Grid.Item className="hc-fs-lg hc-pb-dense" xs={12}>
                  <Heading size={4}>{summary.scac}</Heading>
                </Grid.Item>

                <Grid.Item className="hc-pb-dense" xs>
                  <Grid.Container
                    aria-label={`Outstanding Load Summary for ${summary.scac}`}
                    direction="row"
                    align="center"
                  >
                    {summary?.summaries?.map((details) => {
                      return (
                        details.display_sequence === 1 && (
                          <Grid.Item
                            aria-label={details.display_name}
                            className="first-item"
                            xs={12}
                            md={6}
                            xl={3}
                          >
                            <ActionArea
                              aria-label={`${details.display_name} panel for ${summary.scac}`}
                              as={NavLink}
                              to=".."
                              state={{
                                filters: details.filters,
                              }}
                            >
                              <Card className="first-card">
                                <Grid.Container
                                  direction="column"
                                  className="hc-pl-md hc-pt-md"
                                >
                                  <Grid.Item className="hc-pv-none">
                                    <div className="hc-fs-xs">
                                      {details.display_name}
                                    </div>
                                  </Grid.Item>
                                  <Grid.Item className="hc-pv-none">
                                    <div className="hc-fs-3x">
                                      <strong>{details.count}</strong>
                                    </div>
                                  </Grid.Item>
                                </Grid.Container>
                              </Card>
                            </ActionArea>
                          </Grid.Item>
                        )
                      )
                    })}

                    {summary?.summaries?.map((details) => {
                      return (
                        details.display_sequence === 2 && (
                          <Grid.Item
                            aria-label={details.display_name}
                            className="second-item"
                            xs={12}
                            md={6}
                            xl={3}
                          >
                            <ActionArea
                              aria-label={`${details.display_name} Requiring Action panel for ${summary.scac}`}
                              as={NavLink}
                              to=".."
                              state={{
                                filters: details.filters,
                              }}
                            >
                              <Card className="second-card">
                                <Grid.Container
                                  direction="column"
                                  className="hc-pl-md hc-pt-md"
                                >
                                  <Grid.Item className="hc-pv-none">
                                    <div className="hc-fs-xs">
                                      {details.display_name}
                                    </div>
                                  </Grid.Item>
                                  <Grid.Item className="hc-pv-none">
                                    <div
                                      className={`${
                                        details.count === 0 && 'hc-clr-success'
                                      } ${
                                        details.count !== 0 && 'hc-clr-error'
                                      } hc-fs-3x`}
                                    >
                                      <strong>{details.count}</strong>
                                    </div>
                                  </Grid.Item>
                                </Grid.Container>
                              </Card>
                            </ActionArea>
                          </Grid.Item>
                        )
                      )
                    })}

                    {summary?.summaries?.map((details) => {
                      return (
                        details.display_sequence === 3 && (
                          <Grid.Item
                            aria-label={details.display_name}
                            className="third-item"
                            xs={12}
                            md={6}
                            xl={3}
                          >
                            <ActionArea
                              aria-label={`${details.display_name} Loads Requiring Action panel for ${summary.scac}`}
                              as={NavLink}
                              to=".."
                              state={{
                                filters: details.filters,
                              }}
                            >
                              <Card className="third-card">
                                <Grid.Container
                                  direction="column"
                                  className="hc-pl-md hc-pt-md"
                                >
                                  <Grid.Item className="hc-pv-none">
                                    <div className="hc-fs-xs">
                                      {details.display_name}
                                    </div>
                                  </Grid.Item>
                                  <Grid.Item className="hc-pv-none">
                                    <div
                                      className={`${
                                        details.count === 0 && 'hc-clr-success'
                                      } ${
                                        details.count !== 0 && 'hc-clr-error'
                                      } hc-fs-3x`}
                                    >
                                      <strong>{details.count}</strong>
                                    </div>
                                  </Grid.Item>
                                </Grid.Container>
                              </Card>
                            </ActionArea>
                          </Grid.Item>
                        )
                      )
                    })}

                    {summary?.summaries?.map((details) => {
                      return (
                        details.display_sequence === 4 && (
                          <Grid.Item
                            aria-label={details.display_name}
                            className="fourth-item"
                            xs={12}
                            md={6}
                            xl={3}
                          >
                            <ActionArea
                              aria-label={`${details.display_name} panel for ${summary.scac}`}
                              as={NavLink}
                              to=".."
                              state={{
                                filters: details.filters,
                              }}
                            >
                              <Card className="fourth-card">
                                <Grid.Container
                                  direction="column"
                                  className="hc-pl-md hc-pt-md"
                                >
                                  <Grid.Item className="hc-pv-none">
                                    <div className="hc-fs-xs">
                                      {details.display_name}
                                    </div>
                                  </Grid.Item>
                                  <Grid.Item className="hc-pv-none">
                                    <div
                                      className={`${
                                        details.count === 0 && 'hc-clr-success'
                                      } ${
                                        details.count !== 0 && 'hc-clr-error'
                                      } hc-fs-3x`}
                                    >
                                      <strong>{details.count}</strong>
                                    </div>
                                  </Grid.Item>
                                </Grid.Container>
                              </Card>
                            </ActionArea>
                          </Grid.Item>
                        )
                      )
                    })}
                  </Grid.Container>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          )
        })}
      </Grid.Container>
    </Card>
  )
}

export default FMOutstandingLoadSummary
