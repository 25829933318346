export const DEFAULT_LOAD_SEARCH_FILTERS = {
  load_ids: [],
  trip_ids: [],
  route_ids: [],
  scacs: [],
  pro_numbers: [],
  trailer_numbers: [],
  origin_ids: [],
  destination_ids: [],
  service_codes: [],
  purchase_order_numbers: [],
  schedule_ids: [],
  load_statuses: [],
  driver_status: [],
  alert_statuses: [],
  category_list: [],
  hold_reason_code: [],
  store_ids: [],
  required_actions: [],
  critical_departure_start_time: '',
  critical_departure_end_time: '',
  arrival_start_time: '',
  arrival_end_time: '',
  start_from_date: '',
  start_to_date: '',
  load_create_from_date: '',
  load_create_to_date: '',
}
