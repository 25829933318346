import { useState } from 'react'
import { intersection, isEmpty } from 'lodash'

import { useAuth } from '@praxis/component-auth'

import apiConfig from '../../config/apiConfig'

const useUser = () => {
  const {
    session: {
      userInfo: { memberOf },
    },
  } = useAuth()

  const isUser = !isEmpty(
    intersection(memberOf, apiConfig.auth.requiredPermissions.user),
  )

  const isAdmin = !isEmpty(
    intersection(memberOf, apiConfig.auth.requiredPermissions.admin),
  )

  const isCarrier = !isEmpty(
    intersection(memberOf, apiConfig.auth.requiredPermissions.carrier),
  )

  const userInfo = {
    access: {
      isUser,
      isAdmin,
      isCarrier,
      hasBaseAccess: isUser || isAdmin || isCarrier,
    },
  }

  const [user] = useState(userInfo)

  return user
}

export default useUser
